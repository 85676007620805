import  '../scss/admin.scss'

const $ = require('jquery');
global.$ = global.jQuery = $;

import 'select2/dist/js/select2.full';
import 'select2/dist/js/i18n/es';
import '../../public/bundles/tetranzselect2entity/js/select2entity';
import './metronic/bootstrap.min';
import toastr from './metronic/toastr.min';
import './metronic/scripts.bundle';

global.KTAppOptions = {
    "colors": {
        "state": {
            "brand": "#3d94fb",
            "light": "#ffffff",
            "dark": "#282a3c",
            "primary": "#5867dd",
            "success": "#34bfa3",
            "info": "#3d94fb",
            "warning": "#ffb822",
            "danger": "#fd27eb"
        },
        "base": {
            "label": ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
            "shape": ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"]
        }
    }
};

global.App = {
    spinnerSubmitForm: function() {
        // http://tobiasahlin.com/spinkit
        var $buttonpressed;

        $(document).on('click', 'button', function() {
            $buttonpressed = $(this);
        });

        $(document).on('submit', 'form', function() {
            if ($buttonpressed) {
                //var width = $buttonpressed.width();
                $buttonpressed.attr('disabled', 'disabled');
                $buttonpressed.append('<div class="sk-fading-circle"><div class="sk-circle1 sk-circle"></div><div class="sk-circle2 sk-circle"></div><div class="sk-circle3 sk-circle"></div><div class="sk-circle4 sk-circle"></div><div class="sk-circle5 sk-circle"></div><div class="sk-circle6 sk-circle"></div><div class="sk-circle7 sk-circle"></div><div class="sk-circle8 sk-circle"></div><div class="sk-circle9 sk-circle"></div><div class="sk-circle10 sk-circle"></div><div class="sk-circle11 sk-circle"></div><div class="sk-circle12 sk-circle"></div></div>');
                //$buttonpressed.width(width);
            }

            return true;
        });
    },
    formAjax: function() {
        $(document).on('submit', 'form.formajax', function(e) {
            var postData = $(this).serializeArray();
            var action = $(this).attr('action');
            var target = $(this).data('target') + ' .modal-content';
            var redirect = $(this).data('redirect');

            $.ajax({
                url : action,
                type : "POST",
                data : postData,
                success : function(data)
                {
                    if (data == "OK") {
                        window.location.replace(redirect);
                    }
                    else {
                        $(target).html(data);
                    }
                }
            });

            e.preventDefault(); //STOP default action
        });
    },
    linkRemote: function() {
        $('[data-load-remote]').on('click', function(e) {
            e.preventDefault();

            let $this = $(this);
            let $target = $this.data('remote-target');
            let $remote = $this.data('load-remote');
            let $remove = $this.data('elem-remove');

            if ($remote) {
                $($target).html("");

                $($remove).remove();

                $.get($remote, function (data) {
                    $($target).append(data);
                });
            }
        });
    },
    selectDependientes: function() {
        $(document).on('change', '.select-origen', function(e) {
            var $selectOrigen = $(this);
            var $infoOrigen = $selectOrigen.closest('.select-dependiente');

            if ($infoOrigen.data('dependiente')) {
                var $selectDependiente = $($infoOrigen.data('dependiente')).find('select');
                var $urlDependiente = $infoOrigen.data('dependiente-url');

                if ($selectDependiente.hasClass('select-origen')) {
                    var $infoOrigenDependiente = $selectDependiente.closest('.select-dependiente');

                    if ($infoOrigenDependiente.data('dependiente')) {
                        var $selectDependienteDependiente = $($infoOrigenDependiente.data('dependiente')).find('select');
                        $selectDependienteDependiente.val(null);
                        $selectDependienteDependiente
                            .find('option')
                            .not(':first')
                            .remove()
                            .end();
                    }
                }

                cargaSelectDependiente($selectDependiente, $urlDependiente);
            }

            if ($infoOrigen.data('dependiente2')) {
                var $selectDependiente2 = $($infoOrigen.data('dependiente2')).find('select');
                var $urlDependiente2 = $infoOrigen.data('dependiente2-url');

                cargaSelectDependiente($selectDependiente2, $urlDependiente2);
            }

            if ($infoOrigen.data('dependiente3')) {
                var $selectDependiente3 = $($infoOrigen.data('dependiente3')).find('select');
                var $urlDependiente3 = $infoOrigen.data('dependiente3-url');

                cargaSelectDependiente($selectDependiente3, $urlDependiente3);
            }

            if ($infoOrigen.data('dependiente4')) {
                var $selectDependiente4 = $($infoOrigen.data('dependiente4')).find('select');
                var $urlDependiente4 = $infoOrigen.data('dependiente4-url');

                cargaSelectDependiente($selectDependiente4, $urlDependiente4);
            }

            if ($infoOrigen.data('dependiente5')) {
                var $selectDependiente5 = $($infoOrigen.data('dependiente5')).find('select');
                var $urlDependiente5 = $infoOrigen.data('dependiente5-url');

                cargaSelectDependiente($selectDependiente5, $urlDependiente5);
            }
        });

        function cargaSelectDependiente(selectDependiente, url)
        {
            var entityId = null;
            var entityId2 = null;
            var entityId3 = null;
            var entityId4 = null;
            var entityId5 = null;

            var depende = selectDependiente.closest('.select-dependiente').data('depende');
            if (depende) {
                var selectDepende = $(depende).find('select');
                entityId = selectDepende.val();
            }

            var depende2 = selectDependiente.closest('.select-dependiente').data('depende2');
            if (depende2) {
                var selectDepende2 = $(depende2).find('select');
                entityId2 = selectDepende2.val();
            }

            var depende3 = selectDependiente.closest('.select-dependiente').data('depende3');
            if (depende3) {
                var selectDepende3 = $(depende3).find('select');
                entityId3 = selectDepende3.val();
            }

            var depende4 = selectDependiente.closest('.select-dependiente').data('depende4');
            if (depende4) {
                var selectDepende4 = $(depende4).find('select');
                entityId4 = selectDepende4.val();
            }

            var depende5 = selectDependiente.closest('.select-dependiente').data('depende5');
            if (depende5) {
                var selectDepende5 = $(depende5).find('select');
                entityId5 = selectDepende5.val();
            }

            if (entityId || entityId2 || entityId3 || entityId4 || entityId5) {
                var data = {
                    entityId: entityId,
                    entityId2: entityId2,
                    entityId3: entityId3,
                    entityId4: entityId4,
                    entityId5: entityId5,
                };

                $.ajax({
                    type: 'post',
                    url: url,
                    data: data,
                    success: function (data) {
                        selectDependiente
                            .find('option')
                            .not(':first')
                            .remove()
                            .end();

                        if (selectDependiente.find('option').first().val() > 0) {
                            selectDependiente.find('option').first().remove();
                        }

                        var total = data.length;

                        for (var i = 0; i < total; i++) {
                            //console.log(data[i].value+" - "+data[i].label);
                            selectDependiente.append('<option value="' + data[i].value + '">' + data[i].label + '</option>');
                        }

                        $('.select2entity').select2entity();
                    }
                });
            }
        }
    },
    toastrRun: function(tipo, mensaje) {
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showMethod: 'slideDown',
            positionClass: 'toast-top-center',
            timeOut: 5000
        };

        setTimeout(function() {
            switch (tipo) {
                case 'error':
                    toastr.error(mensaje);
                    break;
                case 'success':
                    toastr.success(mensaje);
                    break;
                case 'warning':
                    toastr.warning(mensaje);
                    break;
                case 'info':
                    toastr.info(mensaje);
                    break;
            }
        }, 1000);
    },
    resultados: function () {
        $('#tabla-close').on('click', function() {
            if ($('#div-resultados-tabla').hasClass('closed')) {
                $('#tabla-close i').attr('class', 'flaticon2-right-arrow');
                $('#div-resultados-tabla .kt-portlet__body').show();
                $('#div-resultados-tabla').attr('class', 'col-md-3 order-lg-2 order-xl-1');
                $('#div-resultados-chart').attr('class', 'col-md-9 order-lg-2 order-xl-1');
            } else {
                $('#tabla-close i').attr('class', 'flaticon2-down-arrow');
                $('#div-resultados-tabla .kt-portlet__body').hide();
                $('#div-resultados-tabla').attr('class', 'closed col-sm-1 order-lg-2 order-xl-1');
                $('#div-resultados-chart').attr('class', 'closed col-sm-11 order-lg-2 order-xl-1');
            }

            drawChart();
        });
    },
    modalResultados: function () {
        $('.btn-filtro-resultados').on('click', function() {
            $('.filtro-resultados').fadeIn();
            $("html, body").animate({ scrollTop: 0 });
        });
        $('.close-filtro-resultados').on('click', function() {
            $('.filtro-resultados').fadeOut();
        });
    },
    init: function() {
        this.spinnerSubmitForm();
        this.formAjax();
        this.linkRemote();
        this.selectDependientes();
        this.resultados();
        this.modalResultados();
    }
};

$(function() {
    App.init();
});